$(document).ready(function () {
  $('.scroll-top').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  $('.scroll').click(function () {
    var anchor = $(this).data('anchor');
    var anchorOffset = $(this).data('anchor-offset');    
    $("html, body").animate({
      scrollTop: $(anchor).offset().top+anchorOffset
    }, $(this).data('anchor-speed'));
  });

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 50) {
      $(".navigation").addClass("fixed");
    }
    if (scroll <= 50) {
      $(".navigation").removeClass("fixed");
    }
  });
});
